/*********************************
 * Header
 ********************************/

document
    .querySelectorAll("[data-nav-location]")
    .forEach((el) => el.addEventListener("click", handleGtmHeaderClick));
document
    .querySelectorAll(".logout, [data-gtm='logout']")
    .forEach((el) => el.addEventListener("click", handleGtmLogoutClick));

function handleGtmLogoutClick(event) {
    const linkEl = event.currentTarget;
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
        event: "logout",
        detailed_event: "User Signed Out",
        user_data: {
            user_id: linkEl.dataset.gtmEmail ?? "",
        },
    });
}

function handleGtmHeaderClick(event) {
    const linkEl = event.currentTarget;
    let ancestory = "";
    if (linkEl.dataset.navLocation === "Desktop Nav") {
        ancestory = "sub_nav";
    } else {
        ancestory = "top_nav";
    }
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({ event_data: null }); // Clear the previous event_data object.
    dataLayer.push({
        event: "click",
        detailed_event: "Navigation Link Clicked",
        event_data: {
            component_ancestry: ancestory,
            identifier: linkEl.textContent,
            link_url: linkEl.href,
            region_ancestry: linkEl.dataset.navLocation,
        },
    });
}

/*********************************
 * Footer
 ********************************/

document
    .querySelectorAll(".social-media-link")
    .forEach((el) => el.addEventListener("click", handleSocialLink));

document
    .querySelectorAll(".footer-nav-link")
    .forEach((el) => el.addEventListener("click", handleFooterNavLink));

function handleSocialLink(event) {
    const el = event.currentTarget;
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({ event_data: null }); // Clear the previous event_data object.
    dataLayer.push({
        event: "subscribe_social",
        detailed_event: "Social Brand Followed",
        event_data: {
            method: el.dataset.socialMedia,
        },
    });
}

function handleFooterNavLink(event) {
    const el = event.currentTarget;
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({ event_data: null }); // Clear the previous event_data object.
    dataLayer.push({
        event: "click",
        detailed_event: "Navigation Link Clicked",
        event_data: {
            component_ancestry: "bottom_nav",
            identifier: el.textContent,
            link_url: el.href,
            region_ancestry: el.dataset.navLocation,
        },
    });
}

/*********************************
 * Booking Widget
 ********************************/

export function submitBookingWidget() {
    var occupancyText = "";
    var occupancyArray = [];
    if ($(".current-rooms-and-people").text()) {
        occupancyText = $(".current-rooms-and-people").text();
        occupancyArray = occupancyText.split(",");
    } else {
        occupancyText = $(".picker__title__text--guests").text();
        occupancyArray = occupancyText.split(",");
    }
    var numAdults = 0;
    var numChildren = 0;
    for (const element of occupancyArray) {
        if (element.includes("adult")) {
            numAdults = +element.match(/\d+/)[0];
        } else if (element.includes("child")) {
            numChildren = +element.match(/\d+/)[0];
        }
    }
    var today = new Date();
    var arrivalDate = new Date($("input[name=arrival]").val());
    var departureDate = new Date($("input[name=departure]").val());
    var hotel = window.searchWidget.hotels.filter((obj) => {
        return obj.id == $("input[name=hotel]").val();
    });

    searchPerformed(hotel[0].name, arrivalDate, departureDate, numAdults, numChildren);
}

/**
 *
 * @param {string} hotel
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {Number} adults
 * @param {Number} children
 */
export function searchPerformed(hotel, startDate, endDate, adults, children) {
    const today = new Date();
    const daysToStartDate = Math.max(Math.round((startDate - today) / (1000 * 60 * 60 * 24)), 0);
    const daysSearchedFor = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event_data: null }); // Clear the previous event_data object.
    window.dataLayer.push({
        event: "search",
        detailed_event: "Onsite Search Performed",
        event_data: {
            days_to_start_date: daysToStartDate,
            onsite_search_date_count: daysSearchedFor,
            start_date: `${new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(startDate)}-${new Intl.DateTimeFormat('en-US', { month: '2-digit' }).format(startDate)}-${new Intl.DateTimeFormat('en-US', { day: '2-digit' }).format(startDate)}`,
            end_date: `${new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(endDate)}-${new Intl.DateTimeFormat('en-US', { month: '2-digit' }).format(endDate)}-${new Intl.DateTimeFormat('en-US', { day: '2-digit' }).format(endDate)}`,
            number_of_adults: adults,
            number_of_children: children,
            people: adults + children,
            search_term: hotel,
        },
    });
}

window.gtmSearchPerformed = searchPerformed;

/*********************************
 * Hotel Code
 ********************************/
let gtmDataObj = {hotel: {code: "", name: "", id:""}};
try{
    gtmDataObj = JSON.parse(document.getElementById("gtm-data").textContent);
} catch(e) {
    console.log("Unable to find page data");
}

/*********************************
 * Default Page Events
 ********************************/

export function pageLoadedEvents(customPageLoadedCallback = () => null) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ page_data: null }); // Clear the previous page_data object.
    window.dataLayer.push({
        event: "page_load_started",
        detailed_event: "Page Load Started",
        page_data: {
            country: "US",
            language: gtmDataObj.page.language,
            name: gtmDataObj.page.name,
            page_location: `${gtmDataObj.page.page_location}${window.location.search}`,
            page_path: gtmDataObj.page.page_path,
            page_title: document.getElementsByTagName("title")[0].textContent,
            platform_version: "Booking Engine",
            site_section: "Booking Engine",
            weekday_or_weekend: gtmDataObj.page.weekday_or_weekend,
        },
    });

    window.dataLayer.push({
        event: "detect_user",
        detailed_event: "User Detected",
        user_data: {
            user_id: gtmDataObj.page.user_id,
            user_login_state: gtmDataObj.page.user_login_state,
        },
    });
    customPageLoadedCallback(gtmDataObj);
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "page_view",
        detailed_event: "Page Load Completed",
    });
}

window.gtmPageLoadedEvents = pageLoadedEvents;

/*********************************
 * Rooms Rates
 ********************************/
export function handleRateSelected(event) {
    "use strict";
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "select_item",
        detailed_event: "Room Listing Item Clicked",
        ecommerce: {
            item_list_id: "search_results",
            item_list_name: "Search Results",
            items: [
                {
                    item_id: gtmDataObj.hotel.code,
                    item_name: gtmDataObj.hotel.name,
                    item_category: event.currentTarget.dataset.rateCode ?? "",
                    item_category2: event.currentTarget.dataset.roomCode ?? "",
                },
            ],
        },
    });
}

export function gtmModalOpen(roomId) {
    "use strict";
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
        event: "view_details",
        detailed_event: "View Room Details",
        ecommerce: {
            items: [
                {
                    item_category2: roomId,
                    item_id: gtmDataObj.hotel.code,
                },
            ],
        },
    });
    window.dataLayer.push({ event_data: null }); // Clear the previous event_data object.
    window.dataLayer.push({
        event: "view_interstitial",
        detailed_event: "Interstitial Viewed",
        event_data: {
            type: "Room Details",
        },
    });
}

/*********************************
 * Filtering & Sorting
 ********************************/

export function updateGtmFilterDataLayer(filterer, event, detailedEvent) {
    const facetsArr = [];
    filterer.appliedFilters.forEach(filter => {
        if (!filter.default) {
            facetsArr.push((filter.gtmType ?? filter.category) + '~' + filter.output);
        }
    });
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": event,
        "detailed_event": detailedEvent,
        "ecommerce": {
            "facets": facetsArr.join("|"),
            "type": "Onsite Filter"
        }
    });

    const visibleRatesArr = [];
    const gtmFilterObj = (itemCategory, itemCategory2, price) => {
        return {
            'item_brand': gtmDataObj.shared.itemBrand ?? "omniHotels",
            // Item brand
            'item_category': itemCategory,
            // Use this variable for the rateCode for the selected room
            'item_category2': itemCategory2, // The Room Type Code
            'item_category3': gtmDataObj.shared.itemCategory3,
            // Pipe separate value for Promo Code & Group Code, i.e. promoCode|groupCode (replace with actual values)
            'item_category4': gtmDataObj.shared.itemCategory4, // The number of adults included in the booking
            'item_category5': gtmDataObj.shared.itemCategory5, // The number of children included in a booking
            'item_id': gtmDataObj.shared.itemId,
            'item_list_name': "Search Results",
            // Item ID (context-specific).The product primary ID (Hotel Code)
            'item_name': gtmDataObj.shared.itemName,
            // Full name of the hotel as listed on the website
            'item_variant': gtmDataObj.shared.itemVariant,
            // The # of days in advance that the reservation is being made vs. the actual booking (booking arrival date - reservation date)
            'location_id': gtmDataObj.shared.locationId,
            // The location associated with the event. If possible, set to the Google Place ID that corresponds to the associated item. Can also be overridden to a custom location ID string.
            'price': `${price}`,
            // The monetary price of the item, in units of the specified currency parameter.
            'quantity': gtmDataObj.shared.quantity, // Item quantity.
        }};
    if(gtmDataObj.filters.ratesListedForFilter) {
        filterer.visibleRooms.forEach(roomEl =>
            roomEl.querySelectorAll('.rate-container[data-filtered-reasons=""]').forEach(
                rateEl => visibleRatesArr.push(gtmFilterObj(rateEl.dataset.rateCode, roomEl.id, rateEl.dataset.price)))
        );
    } else {
        filterer.visibleRooms.forEach(roomEl =>
            visibleRatesArr.push(gtmFilterObj(roomEl.id, roomEl.id, roomEl.dataset.price)));
    }
    window.dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
    window.dataLayer.push({
        "event": "view_item_list",
        "detailed_event": "Room Listing Displayed",
        "ecommerce": {
            'item_list_id': 'search_results',
            'item_list_name': 'Search Results',
            "items": visibleRatesArr
        }
    });
}

export function updateGtmSortDataLayer(sorter) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        "event": "sort_list",
        "detailed_event": "Listing Sort Order Changed",
        "ecommerce": {
            "facets": 'sort~' + sorter.type.sortType,
            "sort_order": sorter.type.sortOrder,
            "type": "Onsite Filter"
        }
    });
}

/*********************************
 * Addons
 ********************************/

export function addAddOn(name, price) {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({ event_data: null }); // Clear the previous event_data object.
    dataLayer.push({
        event: "add_addon",
        detailed_event: "Add Add-on",
        ecommerce: {
            items: [
                {
                    item_id: gtmDataObj.hotel.code,
                },
            ],
        },
        event_data: {
            addon_name: name,
            addon_price: price,
        },
    });
}

export function removeAddOn(name, price) {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({ event_data: null }); // Clear the previous event_data object.
    dataLayer.push({
        event: "remove_addon",
        detailed_event: "Remove Add-on",
        ecommerce: {
            items: [
                {
                    item_id: gtmDataObj.hotel.code,
                },
            ],
        },
        event_data: {
            addon_name: name,
            addon_price: price,
        },
    });
}
